export const routes = {
  Login: "/login",
  Home: "/",
  NewRsume: "/resume/new",
  Dashboard: "/mydocuments",
  ResumeTemplates: "/resume/templates",
  CVTemplates: "/cv/templates",
  Templates: "/templates",
  Editor: "/resume",
  CVEditor: "/cv",
  Pricing: "/pricing",
  Faq: "/faq",
  Prep: "/prep",
  Datasets: "/datasets",
  ChannelPartnerLanding: "/channelpartner",
  ChannelPartnerBenefit: "/channelpartner/benefit",
  ChannelPartnerReg: "/channelpartner/registration",
  ChannelPartnerProfile: "/channelpartner/profile",
  Contracts: "/channelpartner/mycontracts",
  PrivacyPolicy: "/privacy-policy",
  CookiePolicy: "/cookie-policy",
  TermsAndConditions: "/terms-and-conditions",
  PricingPolicy: "/pricing-policy",
  RefundPolicy: "/refund-policy",
  ContactUS: "/contact-us",
  ShippingPolicy: "/shipping-policy",
  Approval: "/channelpartner/approval",
  Admin: "/admin",
  Projects: "/projects",
  PaymentSuccess: "/paymentsuccessful",
  PaymentFailed: "/paymentfailed",
  Mypitch: "/mypitch",
  Register: "/register",
  MyAccount: "/account",
  MySubscription: "/subscription",
  Role: "/role",
  Myprep: "/myprep",
  blogs: "blogs",
  Screenshot: "screenshot",
};
