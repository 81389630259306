import { useRequest, useToggle } from "ahooks";
import { message, Modal } from "antd";
import {
  postLoginGoogle,
  postLoginGoogleChannelPartner,
  postRefreshToken,
} from "api/auth";
import { getUserProfile } from "api/user";

import { routes } from "index/routes";
import {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useState,
  // useEffect
} from "react";

import { getAuthToken, handleLogout, isLoggedIn } from "utils/helpers";
import GoogleLoginBtn from "../components-shared/GoogleLoginBtn";
import { useNavigate } from "react-router-dom";
import useQueryParams from "hooks/useQueryParam";
// import { getUserProfile } from "api/user";

export const AppContext = createContext();

const initialState = {
  isLoading: false,
  isLoaded: true,
  isOpenProfileUpdateModal: false,
  userProfile: {
    name: "",
  },
  code: "",
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_LOADING":
      return { ...state, isLoading: payload };
    case "SET_USER_PROFILE":
      return { ...state, userProfile: payload };
    case "SET_IS_OPEN_PROFILE_UPDATE_MODAL":
      return { ...state, isOpenProfileUpdateModal: payload };
    case "SET_IS_CLOSE_PROFILE_UPDATE_MODAL":
      return { ...state, isOpenProfileUpdateModal: payload };
    case "IS_LOADED":
      return { ...state, isLoaded: false };
    case "SET_CODE":
      return { ...state, code: payload };
    default:
      return state;
  }
}

export function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [toggle, { setLeft, setRight }] = useToggle();
  const navigate = useNavigate();
  const { getParams } = useQueryParams();
  const { referral } = getParams();

  function setLoading(isLoading) {
    dispatch({ type: "SET_LOADING", payload: isLoading });
  }
  function setIsOpenProfileUpdateModal(isOpen) {
    dispatch({ type: "SET_IS_OPEN_PROFILE_UPDATE_MODAL", payload: isOpen });
  }
  function setIsCloseProfileUpdateModal(isOpen) {
    handleLogout();
    dispatch({ type: "SET_IS_OPEN_PROFILE_UPDATE_MODAL", payload: isOpen });
  }
  const {
    data,
    loading,
    run: fetchUserProfile,
  } = useRequest(getUserProfile, {
    manual: true,
    // refreshOnWindowFocus: isLoggedIn(),
    // cacheKey: "App-context",
    onSuccess: (resp) => {
      if (resp.data.data.isNew) {
        localStorage.setItem("role", resp.data.data.role);
        // resp.data.data.role !== "channelpartner" &&
        // setIsOpenProfileUpdateModal(true);
      } else {
        localStorage.setItem("role", resp.data.data.role);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (!data) return;
    dispatch({ type: "SET_USER_PROFILE", payload: data.data.data });
  }, [loading]);

  async function handleSuccessGoogleLoginChannelPartner(payload) {
    setLoading(true);
    let resp;
    if (state.code.length > 0) {
      try {
        resp = await postLoginGoogle({
          token: payload.credential,
          signUpCode: state.code,
        });
        localStorage.setItem("authToken", resp.data.data.token);
        localStorage.setItem("refreshToken", resp.data.data.refreshToken);
        if (resp.data.data.isNew) {
          if (window.location.href.endsWith("/channelpartner")) {
            window.location.href = routes.Dashboard;
            // localStorage.setItem("role", "channelpartner");
          } else {
            window.location.href = routes.Register;
            // window.location.reload();
          }
        } else {
          // localStorage.setItem("role", "channelpartner");
          // window.location.href = routes.ChannelPartnerProfile;
          message.open({
            type: "error",
            content: "This Email Already Exists",
            duration: 2,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error?.response?.data?.error?.message ===
          "Already Registered as Student"
        ) {
          message.open({
            key: "login",
            type: "error",
            content: "The user already exist as a Student",
            duration: 2,
          });
        }
      }
    } else {
      try {
        resp = await postLoginGoogleChannelPartner({
          token: payload.credential,
        });
        console.log(resp);

        localStorage.setItem("authToken", resp.data.data.token);
        localStorage.setItem("refreshToken", resp.data.data.refreshToken);
        setLoading(false);
        if (resp.data.data.isNew) {
          if (window.location.href.endsWith("/channelpartner")) {
            window.location.href = routes.ChannelPartnerReg;
            localStorage.setItem("role", "channelpartner");
          } else {
            window.location.reload();
          }
        } else {
          localStorage.setItem("role", "channelpartner");
          window.location.href = routes.ChannelPartnerProfile;
        }
      } catch (error) {
        setLoading(false);
        if (
          error?.response?.data?.error?.message ===
          "Already Registered as Student"
        ) {
          message.open({
            key: "login",
            type: "error",
            content: "The user already exist as a Student",
            duration: 2,
          });
        }
      }
    }
  }

  async function handleSuccessGoogleLogin(payload) {
    let resp;
    setLoading(true);
    // if (window.location.href.endsWith("/channelpartner")) {

    // } else {
    try {
      resp = await postLoginGoogle({ token: payload.credential });
      console.log(resp);

      localStorage.setItem("authToken", resp.data.data.token);
      localStorage.setItem("refreshToken", resp.data.data.refreshToken);
      setLoading(false);
      const fook = [
        {
          lastModified: "",
          lastModifiedDate: "",
          name: "",
          originFileObj: {
            uid: "",
          },

          percent: 0,
          size: 105900,
          status: "error",
          type: "image/png",
          uid: "",
        },
      ];
      // localStorage.setItem("file", "");
      localStorage.setItem("filelist", JSON.stringify(fook));

      if (resp.data.data.isNew) {
        // window.localStorage.setItem("timestamp", Date.now());
        if (window.location.href.endsWith("/")) {
          window.location.href = routes.Register;
        } else {
          window.location.reload();
        }
      } else {
        localStorage.setItem("role", "student");

        if (window.location.href.endsWith("/")) {
          window.location.reload();
          window.location.href = routes.Dashboard;
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.error?.message ===
        "Already Registered as Channel Partner"
      ) {
        message.open({
          key: "login",
          type: "error",
          content: "The user already exist as a Channel Partner",
          duration: 2,
        });
      } else if (
        error?.response?.data?.error?.message ===
        "Already Registered as corporate"
      ) {
        message.open({
          key: "login",
          type: "error",
          content: "The user already exists as a corporate",
          duration: 2,
        });
      }
    }
    // }
  }

  function refreshUserProfile() {
    fetchUserProfile();
  }

  useEffect(() => {
    isLoggedIn() && fetchUserProfile();
    const handleWindowFocus = () => {
      isLoggedIn() && fetchUserProfile();
    };
    window.addEventListener("focus", handleWindowFocus);
    return () => window.removeEventListener("focus", handleWindowFocus);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ type: "IS_LOADED" });
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    if (window.location.href.includes("/channelpartner")) {
      // const url = window.location.href;
      // const pattern = /\/channelpartner\/([^\/]+)$/;
      // const match = url.match(pattern);
      // const routes = ["benefit", "registration", "profile", "approval"];
      if (referral && referral.length > 0) {
        //   const routeExists = routes.includes(match[1]);
        //   if (!routeExists) {
        navigate(`/`);
        dispatch({ type: "SET_CODE", payload: referral });
        setRight();
        //   }
      }
    }
  }, []);
  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (isLoggedIn() && !refreshToken) handleLogout();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.key === "PrintScreen" ||
        (e.ctrlKey && e.shiftKey && e.key === "S") ||
        (e.metaKey && e.shiftKey)
      ) {
        navigate("/screenshot");
      }
    };

    const handleKeyup = (e) => {
      if (e.key == "PrintScreen") {
        navigate("/screenshot");
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, []);

  const value = {
    state,
    setLoading,
    loading,
    handleSuccessGoogleLogin,
    setIsCloseProfileUpdateModal,
    setIsOpenProfileUpdateModal,
    refreshUserProfile,
    handleSuccessGoogleLoginChannelPartner,
  };

  return (
    <>
      {/* {showWarning && (
        <div className="fixed top-4 right-4 bg-red-500 text-white p-4 rounded-md shadow-lg z-50">
          Screenshots and copying are not allowed
        </div>
      )} */}
      <AppContext.Provider value={value}>{children}</AppContext.Provider>
      <Modal
        title={<span className="text-black">Referral Code: {state.code}</span>}
        open={toggle}
        width={500}
        footer={null}
        maskClosable={false}
        closable={true}
        onCancel={setLeft}
      >
        <div className="flex justify-center items-center">
          <GoogleLoginBtn onSuccess={handleSuccessGoogleLoginChannelPartner} />
        </div>
      </Modal>
    </>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
