import React, { createContext, useContext, useState } from "react";

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [dynamicElements, setDynamicElements] = useState([]);

  const addElementToNavbar = (id, element, order = 0) => {
    setDynamicElements((prev) => {
      // Replace existing element if the ID is the same, otherwise add it
      const updated = [
        ...prev.filter((el) => el.id !== id),
        { id, element, order },
      ];
      // Sort elements by order
      return updated.sort((a, b) => a.order - b.order);
    });
  };

  const removeElementFromNavbar = (id) => {
    setDynamicElements((prev) => prev.filter((el) => el.id !== id));
  };

  return (
    <NavbarContext.Provider
      value={{ dynamicElements, addElementToNavbar, removeElementFromNavbar }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => useContext(NavbarContext);
