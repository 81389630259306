import React, { useEffect } from "react";
import style from "./Footer.module.scss";
import clsx from "clsx";
import Logo from "components-shared/Logo";
import { NavLink } from "react-router-dom";
import { routes } from "index/routes";

const Links = [
  {
    title: "Products",
    links: [
      { title: "Resume", link: "/templates" },
      {
        title: "Interview tools",
        link: routes.Prep,
        // isExternal: true
      },
      {
        title: "Free Templates",
        link: routes.Templates,
        // isExternal: true
      },
      {
        title: "For Companies",
        link: "",
        // isExternal: true
      },
    ],
  },
  {
    title: "Support",
    links: [
      { title: "Help", link: "/faq" },
      { title: "Contact us", link: "/contact-us" },
      // { title: "Terms And Conditions", link: "/terms-and-conditions" },
    ],
  },
  {
    title: "Resources",
    links: [
      { title: "Blogs", link: "" },
      { title: "Case Studies", link: "" },
    ],
  },
  // {
  //   title: "Legal",
  //   links: [
  //     { title: "Privacy", link: routes.PrivacyPolicy },
  //     { title: "Cookie Policy", link: routes.CookiePolicy },
  //     { title: "Refund Policy", link: routes.RefundPolicy },
  //     { title: "Pricing Policy", link: routes.PricingPolicy },
  //     { title: "Shipping Policy", link: routes.ShippingPolicy },
  //   ],
  // },
];

const scrollTop = () => {
  window.scrollTo(0, 0);
};

export default function Footer() {
  return (
    <div className="bg-[#253C86] py-5 mt-32 text-[0.833rem]">
      <div className="container">
        <div
          className={clsx(
            style.wrapper,
            "flex flex-col justify-between gap-8 lg:gap-0 lg:items-center md:flex-row items-center sm:!px-0"
          )}
        >
          <div className={clsx("!min-w-[220px]")} onClick={scrollTop}>
            <Logo theme="dark" w="150" />
          </div>

          <div
            className={clsx(
              style.links,
              "flex flex-col md:flex-row lg:!gap-16 sm:!gap-7 items-center justify-center"
            )}
          >
            {Links.map((item) => (
              <div className={clsx(style.linksCol, "gap-3")} key={item.title}>
                <span className={style.title}>{item.title}</span>
                <nav className="gap-2">
                  {item.links.map((link) => (
                    <span className="whitespace-nowrap" key={link.title}>
                      {
                        <NavLink
                          to={link.link}
                          className={clsx("text-[#B9B9B9] text-left")}
                        >
                          {link.title}
                        </NavLink>
                      }
                    </span>
                  ))}
                </nav>
              </div>
            ))}
            <div
              className={clsx(
                // style.copyright,
                "flex flex-col gap-3 min-w-[250px] max-w-[250px] mt-1"
              )}
            >
              <span className="text-[#fff] font-semibold ">Contact</span>
              <a
                href="mailto:support@razorhire.ai"
                className="text-[#B9B9B9] block mt-4 md:mt-0"
              >
                support@razorhire.ai
              </a>
              <a
                className="text-[#B9B9B9] block"
                href="https://www.google.com/maps/dir//Level+1,+Elemental+%23337,+101,+Road+No.+1,+Malakunta,+Financial+District,+Hyderabad,+Nanakramguda,+Telangana+500032/@17.4131755,78.2611852,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bcb959d27444ec9:0x8a3ba1cae9a5a648!2m2!1d78.343587!2d17.4131924?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noreferrer"
              >
                CCC Digital Private Ltd 101, 1st Floor, Elemental #337, Road No.
                1, Financial District, Hyderabad, Telangana 500032
              </a>
              {/* <a
              href="mailto:support@razorhire.ai"
              className="text-[#B9B9B9] block mt-4 md:mt-0"
            >
              info@cccsnv.com
            </a>
            <a
              href="https://www.cccsnv.com/"
              target="_blank"
              rel="noreferrer"
              className="text-[#B9B9B9] block mt-4 md:mt-0"
            >
              www.cccsnv.com
            </a> */}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="border  border-[#B9B9B9] border-solid md:mt-8 lg:mt-20 md:min-w-[100%] w-full mx-10"></div>
        </div>
        {/* <span className="grid place-items-center mb-0 "> */}
        <div className="flex justify-between pt-4">
          <div className="flex gap-5">
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#B9B9B9]"
            >
              Privacy Policy
            </a>
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#B9B9B9]"
            >
              Terms of use
            </a>
            <a
              href={routes.RefundPolicy}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#B9B9B9]"
            >
              Sales & Refund
            </a>
            {/* <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#B9B9B9]"
          >
            Legal
          </a> */}
          </div>
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#B9B9B9]"
          >
            Copyright &copy; RazorHire 2024
          </a>
        </div>
      </div>
      {/* </span> */}
    </div>
  );
}
